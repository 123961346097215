.language-selector {
  position: relative;
  display: inline-block;

  &.active .flags-container {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 767px) {
    display: row;

    .flags-container {
      flex-direction: row;
      background: transparent;
    }
  }
}


.flags-container {
  display: none;
  position: absolute;
  background-color: black;
  z-index: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  transition: all 1.2s ease-in-out;
  border-radius: 0.8rem;
}

.flag img,
.current-flag img {
  cursor: pointer;

  &:hover,
  &:focus {
    transition: transform 1.2s ease-in-out;
    transform: rotate(-90deg);
  }
}
