.back-to-top-button {
    position: fixed;
    bottom: 90px;
    right: 20px;
    background-color: rgba(0,0,0,0.4);
    color: #fff;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 900;

    &:hover {
        color:#00acee;     
      }

      &:before {
          color: white;
          }

      &.slick-active {
        color:#00acee;
      }
  }

  @media only screen and (max-width: 600px) {
    .back-to-top-button {
      width: 45px;
      height: 45px;
    }
  }
  
  