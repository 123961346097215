//import colors from constants
@import "constants/colors";
@import "constants/fonts";

.main {

    font-family: $rovor-h2h3;

    .hero {
        background-image: url('../img/ror_img_hero.jpg');
        background-size: cover;
        background-position: center;
        background-color: rgba(0, 0, 0, 1);
        border-radius: 0px 0px 5px 5px;
        display: flex;
        justify-content: top;
        height: 85vh;
        flex-direction: column;
        text-align: center;

        @media (max-width: 767px) {
            height: 40vh;
        }

        .text {
            color: white;
            font-size: 4rem;
            font-weight: 600;
            text-align: top;
            text-transform: uppercase;
            letter-spacing: 0.2rem;
            font-weight: bold;
            margin-bottom: 1rem;

            @media (max-width: 767px) {
                font-size: 2rem;
            }

        }

        .text-small {
            color: #fff;
            font-size: 2.6rem;
            text-align: bottom;
            letter-spacing: 0.2rem;
            font-weight: 300;
            margin-bottom: 2rem;

            @media (max-width: 767px) {
                font-size: 1.6rem;
            }

        }
    }

    .welcome {
        color: $primary_black;
        margin-top: 5rem;
        margin-bottom: 5rem;
        letter-spacing: 0.2rem;
        text-align: center;

        .text-container {

            margin-left: 3rem;
            margin-right: 3rem;
            margin-bottom: 3rem;
            border-radius: 1rem;

            @media (max-width: 767px) {
                width: 100%;
                margin: 1rem 0;
            }

            h3 {
                font-size: 2rem;
                font-weight: 100;
            }
        }

        .card-container {
            align-items: center;
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            margin-top: 2rem;

            @media (max-width: 767px) {
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                margin-top: 1rem;
            }

            .card {
                border-radius: 1rem;
                margin: 0.5rem;
                height: 35rem;
                width: 30%;
                margin-bottom: 2rem;

                img {
                    border-radius: 0.5rem;
                    width: 100%;
                    height: 100%;
                    object-fit: fit;
                }

                @media (max-width: 767px) {
                    height: 25rem;
                    width: 90%;
                    margin: 1rem 0;
                }
            }
        }

    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .main {
        .hero {
            .text {
                font-size: 5rem;
            }

            .text-small {
                font-size: 2rem;
            }
        }

        .welcome {

            .card {
                border-radius: 2.5px;
            }
        }
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .main {
        .hero {
            .text {
                font-size: 5rem;
            }

            .text-small {
                font-size: 2rem;
            }
        }

        .welcome {

            .card {
                border-radius: 2.5px;
            }
        }
    }
}